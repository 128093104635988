import { NavLink } from "react-router-dom";
export const BASE_URL = "https://api.prefaceventure.technology/";
// export const BASE_URL = "http://127.0.0.1:8000/";
export const ROUTES = {
  PROFILE: `/api/v1/profile`,
  SEARCH: `api/v1/client/item/search`,
  UPDATE_PROFILE: `api/v1/profile/update`,
  UPDATE_PASSWORD: `api/v1/user/password/update`,
  LIST_NOTIFICATIONS: `api/v1/notifications`,
};
export const CURRENT_PAGE = 1;
export const PER_PAGE = 50;

export const CURRENT_MONTH_ENABLE_DAY = 25;

/* testing */
/*
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAyi1V6YnkXdFspZ7RmXypfrvEas-YdAiU",
  authDomain: "chat-app-d553c.firebaseapp.com",
  projectId: "chat-app-d553c",
  storageBucket: "chat-app-d553c.appspot.com",
  messagingSenderId: "966075719785",
  appId: "1:966075719785:web:4553e1276edb9822b7c2f8",
  measurementId: "G-Y87C51SV6Q"
};

export const VAPID_KEY = 'BDYtX4QsA1DZscJ7uiMVWhddH69yr6U5K1lkFonFjnW8x5ATqEmwr1waq2MFJ2ohGtrcQfsSbmqwtEacAhZrefM';
*/
/* testing */


/* production */

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCopteGYdsbVuf2eTpvar26TF1SzpIkPug",
  authDomain: "preface-24225.firebaseapp.com",
  projectId: "preface-24225",
  storageBucket: "preface-24225.appspot.com",
  messagingSenderId: "958249678337",
  appId: "1:958249678337:web:451f5355932ae951483200",
  measurementId: "G-FQKFYEV7ZD"
};

export const VAPID_KEY = 'BMeui1dwlceYzAn7kCmVm0VCHvvr66nf9F8AtL-Yr4-CVe1lb8ieJJGmgcZppRcpb0_jfMm4Oe9y6ZrkqlshP0U';

/* production */
export const TABLE_HEADERS = {
  1: [
    { key: "client_name", label: "CLIENT NAME" },
    { key: "gstin", label: "GST NUMBER" },
    {
      key: "id",
      label: "Client",
      modifier: (data, callback) => (
        <NavLink onClick={callback} to={`/periods/${data.id}`}>
          View
        </NavLink>
      ),
    },
    {
      key: "id",
      label: "Form",
      modifier: (data, callback) => (
        <NavLink onClick={callback} to={`/clients/tax-forms/${data.id}`}>
          View
        </NavLink>
      ),
    },
  ],
  2: [
    { key: "client_name", label: "CLIENT NAME" },
    { key: "gstin", label: "GST NUMBER" },
    {
      key: "id",
      label: "#",
      modifier: (data, callback) => (
        <NavLink onClick={callback} to={`/periods/${data.id}`}>
          View
        </NavLink>
      ),
    },
  ],
  3: [
    { key: "client", subkey: "client_name", label: "CLIENT NAME" },
    { key: "file_name", label: "FILE_NAME" },
    {
      key: "folder",
      subkey: "id",
      label: "#",
      modifier: (data, callback) => (
        <NavLink
          onClick={callback}
          to={`/files/${data.folder.id}?file_name=${data.search_text}`}
        >
          View
        </NavLink>
      ),
    },
  ],
};
